"use client"
import { Button } from "@nextui-org/react"

export default function NotFound() {
  return (
    <div className="flex h-[calc(100vh-120px)] flex-col items-center justify-center px-6">
      <h1 className="text-7xl font-semibold text-gray-800">404</h1>
      <h2 className="text-4xl font-medium text-gray-700">Not Found</h2>
      <p className="mb-8 mt-4 text-center text-gray-600">
        페이지를 찾지 못했습니다.
        <br />
        입력하신 주소가 정확한지 다시 한번 확인해 주세요.
      </p>
      <Button
        color="primary"
        onClick={() => {
          window.location.href = "/"
        }}
      >
        홈으로 돌아가기
      </Button>
    </div>
  )
}
